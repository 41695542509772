<script setup>
const social = [
  {
    name: "Twitter",
    href: "https://twitter.com/viminorge",
    icon: "ri:twitter-x-fill",
  },
];
</script>

<template>
  <div>
    <main class="w-full px-6 pt-10 pb-16 mx-auto max-w-7xl sm:pb-24 lg:px-8">
      <NuxtLink to="/"
        ><VimiLogo class="w-auto mx-auto h-28" alt="Vimi" />
      </NuxtLink>
      <slot></slot>
    </main>
    <footer class="py-6 border-t vimi-border sm:py-10">
      <div
        class="flex flex-col items-center justify-center gap-8 px-6 mx-auto max-w-7xl sm:flex-row lg:px-8">
        <p class="text-sm leading-7 text-gray-400">
          &copy; VimiCrypto. All rights reserved.
        </p>
        <div
          class="hidden sm:block sm:h-7 sm:w-px sm:flex-none sm:bg-gray-200" />
        <div class="flex gap-x-4">
          <UButton
            v-for="(item, itemIdx) in social"
            target="_blank"
            :key="itemIdx"
            :to="item.href"
            variant="link"
            class="text-gray-400 hover:text-gray-500">
            <span class="sr-only">{{ item.name }}</span>
            <UIcon :name="item.icon" class="w-6 h-6" aria-hidden="true" />
          </UButton>
        </div>
      </div>
    </footer>
  </div>
</template>
